@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/campton');

body {
  font-family: 'Campton', sans-serif;
}

{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.containerBackground {
  z-index: 10;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensures particles are in the background */
}

.particles canvas {
  height: 100%;
  width: 100%;
  position: absolute;
}

.blinking-cursor {
  border-right: 2px solid white;
  animation: blink 2s infinite;
}

@keyframes blink {
  0%, 50%, 100% {
      border-color: white;
  }
  25%, 75% {
      border-color: transparent;
  }
}

.static-cursor {
  border-right: 2px solid white;
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: left;
  z-index: 50;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: right;
  z-index: 50;
}




