@keyframes highlight {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  
  .highlight {
    background-image: linear-gradient(
      120deg,
      white,
      #ffa500,
      #ff4500,
      white
    );
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: highlight 10s linear infinite;
  }
  